import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CButton from "../../components/CButton";
import {
  assignmentReportData,
  downloadAssignmentReport,
  reset,
} from "../../slices/assignmentReportSlice";
import { fetchAllEmployees, resetEmployees } from "../../slices/employeeSlice";
import { convertToSouthAfricaTime } from "../../common/utils";
import { DataGrid } from "@mui/x-data-grid";
import { fetchAllCompanies } from "../../slices/companySlice";
import CTextField from "../../components/CTextfield";

const AssignmentReport = () => {
  const dispatch = useDispatch();
  const { employeeList } = useSelector((state) => state.employee);
  const { totalRows, assignmentData } = useSelector(
    (state) => state.assignmentReport
  );
  const { companyList } = useSelector((state) => state.company);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [error, setError] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [totalRowsData, setTotalRowsData] = useState(0);
  const [assignmentDetails, setAssignmentDetails] = useState([]);
  const [downloadCsv, setDownloadCsv] = useState(false);

  const [idNo, setIdNo] = useState(null);

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      //editable: true,
    },
    {
      field: "employeeNo",
      headerName: "Employee Number",
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      //editable: true,
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      //editable: true,
    },
    {
      field: "lastName",
      headerName: "Surname",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },
    {
      field: "idNo",
      headerName: "ID number",
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      //editable: true,
    },
    {
      field: "lmaContactNo",
      headerName: "Employee Contact Number",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },
    {
      field: "loginDateTime",
      headerName: "Work Start at",
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <div>{convertToSouthAfricaTime(params.row.loginDateTime)}</div>;
      },
      //editable: true,
    },
    {
      field: "logoutDateTime",
      headerName: "Work Complete at",
      flex: 1.8,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <div>{convertToSouthAfricaTime(params.row.logoutDateTime)}</div>;
      },
      //editable: true,
    },
    {
      field: "workTime",
      headerName: "Work Time",
      flex: 1.5,
      disableColumnMenu: true,
    },
    {
      field: "totalActiveTime",
      headerName: "Work total",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },
    {
      field: "workInProgressNo",
      headerName: "Work description",
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div>
            {params.row.workInProgressNo ? params.row.workInProgressNo : " "}
          </div>
        );
      },
      //editable: true,
    },
  ];

  useEffect(() => {
    const fetchAllEmployeeAPI = () => {
      let payload = {
        pagesData: {
          page: 1,
          limit: -1,
        },
        companyId: selectedCompany,
      };
      dispatch(fetchAllEmployees(payload));
    };
    selectedCompany && fetchAllEmployeeAPI();
  }, [selectedCompany, dispatch]);

  useEffect(() => {
    const fetchAllCompaniesAPI = () => {
      let payload = {
        page: 1,
        limit: -1,
      };
      dispatch(fetchAllCompanies(payload));
    };
    fetchAllCompaniesAPI();
  }, [dispatch]);

  useEffect(() => {
    setTotalRowsData(totalRows);
  }, [totalRows]);

  useEffect(() => {
    setAssignmentDetails(assignmentData);
  }, [assignmentData]);

  useEffect(() => {
    if (employeeList.length > 0) setSelectedEmployee("All");
  }, [employeeList]);

  useEffect(() => {
    dispatch(reset());
    dispatch(resetEmployees());
  }, [dispatch]);

  useEffect(() => {
    const fetchAssignmentDetails = () => {
      let payload = {
        pageData: {
          ...(idNo && { idNo:String(idNo) }),
          page: paginationModel.page + 1,
          limit: paginationModel.pageSize,
        },
        employeeData: {
          ...(selectedEmployee !== "All" && { employeeId: selectedEmployee }),
          employerId: selectedCompany,
          startDate: fromDate?.format("DD/MM/YYYY"),
          endDate: toDate?.format("DD/MM/YYYY"),
        },
      };
      dispatch(assignmentReportData(payload));
    };

    if (
      (toDate && fromDate && selectedCompany && selectedEmployee) ||
      (selectedCompany && selectedEmployee && !toDate && !fromDate)
    ) {
      fetchAssignmentDetails();
    }
    if (!moment(fromDate).isValid() || !moment(toDate).isValid()) {
      setError("Please fill out necessary details");
    } else if (dayjs(fromDate).isAfter(dayjs(toDate))) {
      toast.error("Invalid Date Range");
      setError("Invalid Date Range");
    } else if (!selectedEmployee) {
      setError("Please fill out necessary details");
    } else {
      setError("");
    }
  }, [fromDate, toDate, selectedEmployee, paginationModel, idNo, dispatch]);

  const handlePaginationChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  const handleDownload = (resUrl) => {
    if (resUrl) {
      const url =
        resUrl.startsWith("http") || resUrl.startsWith("https")
          ? resUrl
          : `http://${resUrl}`;

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleSubmit = () => {
    if (!error) {
      let payload = {
        ...(selectedEmployee !== "All" && { employeeId: selectedEmployee }),
        employerId: selectedCompany,
        startDate: fromDate?.format("DD/MM/YYYY"),
        endDate: toDate?.format("DD/MM/YYYY"),
      };
      dispatch(downloadAssignmentReport(payload)).then((res) => {
        if (!res.error) {
          handleDownload(res.payload.data.url);
          setDownloadCsv(true);
        }
      });
    } else {
      toast.error(error);
    }
  };

  const Placeholder = ({ children }) => {
    return <div>{children}</div>;
  };

  return (
    <div className="main-content report-content">
      <h2>Work Report</h2>
      <div className="card-box">
        <div className="report-table-filter">
          <div className="report-table-left">
            <p className="date-range-label">Date Range</p>
            <div className="date-range-fields">
              <div className="CDatepicker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={fromDate || null}
                    onChange={(newValue) => {
                      setFromDate(newValue);
                      setPaginationModel({
                        page: 0,
                        pageSize: 25,
                      });
                    }}
                  />
                </LocalizationProvider>
              </div>
              <p>to</p>
              <div className="CDatepicker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={toDate || null}
                    onChange={(newValue) => {
                      setToDate(newValue);
                      setPaginationModel({
                        page: 0,
                        pageSize: 25,
                      });
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <div className="report-table-right">
            <FormControl fullWidth className="CSelect">
              <InputLabel id="demo-simple-select-label">Employer</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="Employee"
                name="employee"
                id="demo-simple-select"
                displayEmpty
                renderValue={
                  selectedCompany !== null
                    ? undefined
                    : () => <Placeholder>Select Employer</Placeholder>
                }
                onChange={(e) => {
                  setDownloadCsv(false);
                  setSelectedEmployee(null);
                  setSelectedCompany(e.target.value);
                  setPaginationModel({
                    page: 0,
                    pageSize: 25,
                  });
                  dispatch(reset());
                }}
                placeholder="Select"
                value={selectedCompany || ""}
                IconComponent={(props) => <ExpandMoreIcon {...props} />}
                inputlabelprops={{ shrink: true }}
              >
                {companyList?.map((data) => (
                  <MenuItem key={data._id} value={data._id}>
                    {data?.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className="CSelect">
              <InputLabel id="demo-simple-select-label">Employee</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="Employee"
                name="employee"
                id="demo-simple-select"
                displayEmpty
                renderValue={
                  selectedEmployee !== null
                    ? undefined
                    : () => <Placeholder>Select Employee</Placeholder>
                }
                onChange={(e) => {
                  setSelectedEmployee(e.target.value);
                  setPaginationModel({
                    page: 0,
                    pageSize: 25,
                  });
                }}
                placeholder="Select"
                value={selectedEmployee || ""}
                disabled={!employeeList.length}
                IconComponent={(props) => <ExpandMoreIcon {...props} />}
                inputlabelprops={{ shrink: true }}
              >
                <MenuItem value="All">All</MenuItem>
                {employeeList?.map((data) => (
                  <MenuItem key={data._id} value={data._id}>
                    {data?.firstName + " " + data?.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <CTextField
              label={"ID number"}
              disabled={!selectedCompany}
              placeholder={"ID number"}
              value={idNo}
              type="number"
              handleChange={(e) => {
                setIdNo(e.target.value);
                setPaginationModel({
                  page: 0,
                  pageSize: 25,
                });
              }}
            />

            <CButton
              className="btn-primary"
              disabled={assignmentDetails.length <= 0 || !!error}
              onClick={handleSubmit}
            >
              Export CSV
            </CButton>
          </div>
        </div>
        <Box className="table-box">
          <Box className="table-box report_table">
            <DataGrid
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    _id: false,
                  },
                },
                // sorting: {
                //   sortModel: [{ field: 'id', sort: 'desc' }],
                // },
                pagination: { paginationModel: { pageSize: 25 } },
              }}
              rowCount={totalRowsData || 0}
              paginationModel={paginationModel}
              columns={columns}
              rows={assignmentDetails}
              paginationMode="server"
              autoHeight={false}
              getRowId={(row) => row._id}
              rowSelection={false}
              // hideFooterPagination
              // hideFooter
              onPaginationModelChange={(e) => handlePaginationChange(e)}
              onChange
              //pagination={true}
              disableColumnFilter
              hover={false}
              localeText={{ noRowsLabel: "No records found" }}
            />
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default AssignmentReport;
