import { TextField } from "@mui/material";
import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

const CTextField = React.forwardRef(
  (
    { label, disabled, name, helperText, placeholder, handleChange, ...other },
    ref,
    props
  ) => {
    return (
      <TextField
        className="CTextField"
        disabled={disabled}
        fullWidth
        label={label}
        placeholder={placeholder}
        onChange={handleChange}
        InputLabelProps={{ shrink: true }} 
        // InputProps={{
        //   startAdornment: (
        //     <InputAdornment position="start">
        //       <SearchOutlinedIcon />
        //     </InputAdornment>
        //   ),
        // }}
      />
    );
  }
);

export default CTextField;
