export const API_URLS = {
  LOGIN: "/v1/auth/login",
  LOGOUT: "/v1/auth/logout",
  EMPLOYEE_LIST: "/v1/employee/list",
  EMPLOYEE_REPORT: "/v1/employee/report",
  COMPANY_LIST: "/v1/employer/list",
  COMPANY_CREATE: "/v1/employer/create",
  COMPANY: "v1/employer",
  SALARY_REPORT: "/v1/report/salaries?type=csv&page=1",
  SALARY_DATA: "/v1/report/salaries",
  ASSIGNMENT_REPORT: "/v1/report/assignments?type=csv&page=1",
  ASSIGNMENT_DATA: "/v1/report/assignments",
  ANSWER_REPORT: "/v1/report/employeeAnswer?type=csv&page=1",
  ANSWER_DATA: "/v1/report/employeeAnswer",
  IMPORT_CSV: "/v1/employer/importCSV",
  REMOVE_ASSETS: "/v1/employer/remove",
  MODULE_LIST: "/v1/module/list",
  MODULE_CREATE: "/v1/module/create",
  TASK_LIST: "/v1/task/list",
  TASK_CREATE: "/v1/task/create",
  ASSIGNMENT_CREATE: "/v1/assignments/create",
  ASSIGNMENT_LIST: "/v1/assignments/list",
  TASK: "/v1/task/",
  MODULE: "/v1/module/",
  MODULE_UPDTAE: "/v1/module/update",
  TASK_UPDATE: "/v1/task/update",
  ASSIGNMENT: "/v1/assignments",
  ASSIGNMENT_UPDATE: "/v1/assignments/update",
};

export const STATUS_CODES = {
  SUCCESS: 200,
};

export const TIMEZONE_OFFSET = 2;

export const pageMode = {
  ADD: "add",
  EDIT: "edit",
  VIEW: "view",
};

export const FILE_SIZE = 20 * 1024 * 1024; // 20 MB

export const adminMails = [
  "admin@domain.com",
  "prashant.suthar@zealousys.com",
  "admin@gmail.com",
];
