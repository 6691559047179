import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CButton from "../../components/CButton";
import {
  answerReportData,
  downloadAnswerReport,
  reset,
} from "../../slices/answerReportSlice";
import { fetchAllEmployees, resetEmployees } from "../../slices/employeeSlice";
import moment from "moment";
import { fetchAllCompanies } from "../../slices/companySlice";
import CTextField from "../../components/CTextfield";

const AssignmentAnswerReport = () => {
  const dispatch = useDispatch();
  const { employeeList } = useSelector((state) => state.employee);
  const { companyList } = useSelector((state) => state.company);
  const { totalRows, answerData } = useSelector((state) => state.answerReport);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [error, setError] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [totalRowsData, setTotalRowsData] = useState(0);
  const [answerDetails, setAnswerDetails] = useState([]);
  const [downloadCsv, setDownloadCsv] = useState(false);

  const [idNo, setIdNo] = useState(null);

  useEffect(() => {
    const fetchAllEmployeeAPI = () => {
      let payload = {
        pagesData: {
          page: 1,
          limit: -1,
        },
        companyId: selectedCompany,
      };
      dispatch(fetchAllEmployees(payload));
    };
    selectedCompany && fetchAllEmployeeAPI();
  }, [selectedCompany, dispatch]);

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      //editable: true,
    },
    {
      field: "employeeNumber",
      headerName: "Employee No",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
      renderCell: (params) => {
        return <div>{params.row.employee.employeeNumber}</div>;
      },
    },
    {
      field: "inititals",
      headerName: "Inititals",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <div>{params.row.employee.firstName[0]}{params.row.employee.lastName[0]}</div>;
      },
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      //editable: true,
      renderCell: (params) => {
        return <div>{params.row.employee.firstName}</div>;
      },
    },
    {
      field: "lastName",
      headerName: "Surname",
      flex: 1,
      disableColumnMenu: true,
      //editable: true,
      renderCell: (params) => {
        return <div>{params.row.employee.lastName}</div>;
      },
    },
    {
      field: "lmaContactNo",
      headerName: "Contact Number",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
      renderCell: (params) => {
        return <div>{params.row.employee.lmaContactNo}</div>;
      },
    },
    {
      field: "employerName",
      headerName: "Employer",
      flex: 1,
      disableColumnMenu: true,
      //editable: true,
      renderCell: (params) => {
        return <div>{params.row.employee.employerName}</div>;
      },
    },
    {
      field: "idNo",
      headerName: "Id number",
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      //editable: true,
      renderCell:(params)=>{
        return <>{params.row.employee.idNo}</>
      }
    },
    {
      field: "module",
      headerName: "Work in Progress Name",
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <div>{params.row.module.title}</div>;
      },
    },
    {
      field: "question",
      headerName: "Question",
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => {
        const textLength = 50;
        const result =
        params.row.assignment.title?.length > textLength
            ? params.row.assignment.title.slice(0, textLength).trim() + "..."
            : params.row.assignment.title;
        return <div style={{wordWrap:"break-word",overflow:"hidden"}}>{result}</div>;
      },
    },
    {
      field: "answer",
      headerName: "Answer",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
      renderCell: (params) => {
        const textLength = 50;
        const result =
          params.row.answer?.length > textLength
            ? params.row.answer.slice(0, textLength).trim() + "..."
            : params.row.answer;
        return <div style={{wordWrap:"break-word",overflow:"hidden"}}>{result}</div>;
      },
    },
  ];

  useEffect(() => {
    setTotalRowsData(totalRows);
  }, [totalRows]);

  useEffect(() => {
    setAnswerDetails(answerData);
  }, [answerData]);

  useEffect(() => {
    if (employeeList.length > 0) setSelectedEmployee("All");
  }, [employeeList]);

  useEffect(() => {
    dispatch(reset());
    dispatch(resetEmployees());
  }, [dispatch]);

  useEffect(() => {
    const fetchAllCompaniesAPI = () => {
      let payload = {
        page: 1,
        limit: -1,
      };
      dispatch(fetchAllCompanies(payload));
    };
    fetchAllCompaniesAPI();
  }, [dispatch]);

  useEffect(() => {
    const fetchAnswerDetails = () => {
      let payload = {
        pageData: {
          ...(idNo && { idNo:String(idNo) }),
          page: paginationModel.page + 1,
          limit: paginationModel.pageSize,
        },
        employeeData: {
          ...(selectedEmployee !== "All" && { employeeId: selectedEmployee }),
          employerId: selectedCompany,
          startDate: fromDate?.format("DD/MM/YYYY"),
          endDate: toDate?.format("DD/MM/YYYY"),
        },
      };
      dispatch(answerReportData(payload));
    };

    if (
      (toDate && fromDate && selectedCompany && selectedEmployee) ||
      (selectedCompany && selectedEmployee && !toDate && !fromDate)
    ) {
      fetchAnswerDetails();
    }

    if (!moment(fromDate).isValid() || !moment(toDate).isValid()) {
      setError("Please fill out necessary details");
    } else if (dayjs(fromDate).isAfter(dayjs(toDate))) {
      toast.error("Invalid Date Range");
      setError("Invalid Date Range");
    } else if (!selectedEmployee) {
      setError("Please fill out necessary details");
    } else {
      setError("");
    }
  }, [fromDate, toDate, selectedEmployee,idNo, paginationModel, dispatch]);

  const handlePaginationChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  const handleDownload = (resUrl) => {
    if (resUrl) {
      const url =
        resUrl.startsWith("http") || resUrl.startsWith("https")
          ? resUrl
          : `http://${resUrl}`;

      //   fetch(url)
      //     .then((response) => response.blob())
      //     .then((blob) => {
      //       const url = window.URL.createObjectURL(blob);
      //       const link = document.createElement('a');
      //       link.href = url;
      //       link.setAttribute('download', 'salaryreport.csv');
      //       document.body.appendChild(link);
      //       link.click();
      //       document.body.removeChild(link);
      //     })
      //     .catch((error) => {
      //       console.error('Error downloading contract:', error);
      //     });

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleSubmit = () => {
    if (!error) {
      let payload = {
        ...(selectedEmployee !== "All" && { employeeId: selectedEmployee }),
        employerId: selectedCompany,
        startDate: fromDate?.format("DD/MM/YYYY"),
        endDate: toDate?.format("DD/MM/YYYY"),
      };
      dispatch(downloadAnswerReport(payload)).then((res) => {
        if (!res.error) {
          handleDownload(res.payload.data.url);
          setDownloadCsv(true);
        }
      });
    } else {
      toast.error(error);
    }
  };

  const Placeholder = ({ children }) => {
    return <div>{children}</div>;
  };

  return (
    <div className="main-content report-content">
      <h2>Supervisor Report</h2>
      <div className="card-box">
        <div className="report-table-filter">
          <div className="report-table-left">
            <p className="date-range-label">Date Range</p>
            <div className="date-range-fields">
              <div className="CDatepicker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={fromDate || null}
                    onChange={(newValue) => {
                      setFromDate(newValue);
                      setPaginationModel({
                        page: 0,
                        pageSize: 25,
                      });
                    }}
                  />
                </LocalizationProvider>
              </div>
              <p>to</p>
              <div className="CDatepicker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={toDate || null}
                    onChange={(newValue) => {
                      setToDate(newValue);
                      setPaginationModel({
                        page: 0,
                        pageSize: 25,
                      });
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <div className="report-table-right">
            <FormControl fullWidth className="CSelect">
              <InputLabel id="demo-simple-select-label">Employer</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="Employee"
                name="employee"
                id="demo-simple-select"
                displayEmpty
                renderValue={
                  selectedCompany !== null
                    ? undefined
                    : () => <Placeholder>Select Employer</Placeholder>
                }
                onChange={(e) => {
                  setDownloadCsv(false);
                  setSelectedEmployee(null);
                  setSelectedCompany(e.target.value);
                  setPaginationModel({
                    page: 0,
                    pageSize: 25,
                  });
                  dispatch(reset());
                }}
                placeholder="Select"
                value={selectedCompany || ""}
                IconComponent={(props) => <ExpandMoreIcon {...props} />}
                inputlabelprops={{ shrink: true }}
              >
                {companyList?.map((data) => (
                  <MenuItem key={data._id} value={data._id}>
                    {data?.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className="CSelect">
              <InputLabel id="demo-simple-select-label">Employee</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="Employee"
                name="employee"
                id="demo-simple-select"
                displayEmpty
                renderValue={
                  selectedEmployee !== null
                    ? undefined
                    : () => <Placeholder>Select Employee</Placeholder>
                }
                onChange={(e) => {
                  setSelectedEmployee(e.target.value);
                  setPaginationModel({
                    page: 0,
                    pageSize: 25,
                  });
                }}
                placeholder="Select"
                value={selectedEmployee || ""}
                disabled={!employeeList.length}
                IconComponent={(props) => <ExpandMoreIcon {...props} />}
                inputlabelprops={{ shrink: true }}
              >
                <MenuItem value="All">All</MenuItem>
                {employeeList?.map((data) => (
                  <MenuItem key={data._id} value={data._id}>
                    {data?.firstName + " " + data?.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <CTextField
              label={"ID number"}
              disabled={!selectedCompany}
              placeholder={"ID number"}
              value={idNo}
              type="number"
              handleChange={(e) => {
                setIdNo(e.target.value);
                setPaginationModel({
                  page: 0,
                  pageSize: 25,
                });
              }}
            />

            <CButton
              className="btn-primary"
              disabled={!!error || answerDetails.length <= 0}
              onClick={handleSubmit}
            >
              Export CSV
            </CButton>
          </div>
        </div>
        <Box className="table-box report_table">
          <DataGrid
            initialState={{
              columns: {
                columnVisibilityModel: {
                  _id: false,
                },
              },
              // sorting: {
              //   sortModel: [{ field: "question", sort: "desc" }],
              // },
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            rowCount={totalRowsData || 0}
            paginationModel={paginationModel}
            columns={columns}
            rows={answerDetails}
            paginationMode="server"
            autoHeight={false}
            getRowId={(row) => row._id}
            columnHeaderHeight={48}
            getRowHeight={() => "auto"}
            rowSelection={false}
            // hideFooterPagination
            // hideFooter
            onPaginationModelChange={(e) => handlePaginationChange(e)}
            onChange
            //pagination={true}
            disableColumnFilter
            hover={false}
            localeText={{ noRowsLabel: "No records found" }}
          />
        </Box>
      </div>
    </div>
  );
};

export default AssignmentAnswerReport;
