import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import commonService from '../common/commonService';
import { API_URLS, STATUS_CODES } from '../common/constants';
import { toast } from 'react-toastify';
import { startLoading, stopLoading } from './commonSlice';

const initialState = {
  errorMessage: '',
  error: null,
  employeeList: [],
};

export const fetchAllEmployees = createAsyncThunk(
  'employee/list',
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.getRequest(
      API_URLS.EMPLOYEE_LIST +
        `/${data.companyId}` +
        `?${commonService.getQueryFromObject(data.pagesData)}`
    );
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

export const downloadEmployeeReport = createAsyncThunk(
  "employee/report",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.postRequest(API_URLS.EMPLOYEE_REPORT, data);
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    toast.success(res.message);
    return res;
  }
);

const employeeSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    resetEmployees: (state) => {
      return {
        ...state,
        error: null,
        employeeList: [],
        totalRows: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllEmployees.pending, (state) => {
        return {
          ...state,
          error: null,
        };
      })
      .addCase(fetchAllEmployees.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          employeeList: action.payload.data,
          totalPages: action.payload.totalPages,
          totalRows: action.payload.totalDocs,
        };
      })
      .addCase(fetchAllEmployees.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
        };
      })
      .addCase(downloadEmployeeReport.pending, (state) => {
        return {
          ...state,
          error: null,
          reportData: [],
        };
      })
      .addCase(downloadEmployeeReport.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          reportData: action.payload.data,
          totalRows: action.payload.totalDocs,
        };
      })
      .addCase(downloadEmployeeReport.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          reportData: [],
        };
      });
  },
});

export const { resetEmployees } = employeeSlice.actions;
const { reducer } = employeeSlice;
export default reducer;
